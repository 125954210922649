import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { PRODUCTS_MAP, CORE_PRODUCTS } from 'lib/products/constants'
import { PRODUCT_LINKS_MAP } from 'lib/navigation/constants'

import ProductCard from './ProductCard'

const StyledHomeProducts = styled.ul`
  ${screenMin.l`
    display: flex;
    justify-content: center;
    align-items: stretch;
  `}

  & li {
    margin-bottom: ${GTR.M};

    ${screenMin.l`
      width: ${100 / CORE_PRODUCTS.length}%;
      max-width: 400px;
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-right: ${GTR.M};
      }
    `}
  }
`

const StyledProductCard = styled(ProductCard)`
  height: 100%;
`

const HomeProducts = (): ReactElement => (
  <StyledHomeProducts>
    {CORE_PRODUCTS.map((id) => {
      const product = PRODUCTS_MAP[id]
      const productPath = PRODUCT_LINKS_MAP[id].path

      return (
        <li key={product.title}>
          <StyledProductCard
            title={product.title}
            path={productPath}
            illustration={product.illustration}
            illustrationAspectRatio={product.illustrationAspectRatio}
            description={product.description}
            ctaText={product.ctaText}
          />
        </li>
      )
    })}
  </StyledHomeProducts>
)

export default HomeProducts
