import React from 'react'
import styled from 'styled-components'
import { Grid, H, P, Image } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'
import { AWARD_LOGO_URL } from 'lib/awards/constants'

const StyledImage = styled(Image)`
  aspect-ratio: 470 / 118;

  ${screenMin.xl`
    max-width: 470px;
  `}
`

const Team = (): React.ReactElement => (
  <Grid gapFromL="L" style={{ alignItems: 'center' }}>
    <Grid.Item spanFromL={8} spanFromXL={7}>
      <H size="L" decorative>
        <PreventOrphan>The UK’s best-rated death specialists</PreventOrphan>
      </H>

      <P>
        Our specialist team has been placed first at the Good Funeral Awards,
        the British Wills & Probate Awards and more, and our customers have
        rated us Excellent on TrustPilot.
      </P>
    </Grid.Item>

    <Grid.Item
      spanFromL={4}
      spanFromXL={5}
      startColumnFromL={9}
      startColumnFromXL={8}
    >
      <StyledImage
        path={AWARD_LOGO_URL.ACCOLADES_WILLS_PROBATE_LAW_FUNERALS_NO_YEAR}
        width={250}
        widthFromM={300}
        widthFromXL={650}
      />
    </Grid.Item>
  </Grid>
)

export default Team
